@media (prefers-color-scheme: dark) {
  .smoothcover {
    background: black;
  }
}

body {
  overflow: hidden;
}

.modal-dialog-centered.middlesizeold .modal-content {
  overflow: auto;
}

.text-toolbar {
  color: var(--toolbar-text) !important;
}

.text-toolbarbg {
  color: var(--toolbar-background) !important;
}

.bg-lightgrey {
  background: lightgrey !important;
  color: grey !important;
}

.text-dark-toolbar {
  color: var(--toolbar-dark-text) !important;
}

.bg-toolbar.bg-toolbar {
  background-color: var(--toolbar-background) !important;
  border-color: var(--toolbar-text) !important;
}

.spec-dropdown {
  list-style: none;
}

#dimmi .modal-content {
  position: static;
}

#root {
  overflow: hidden;
}

.homescreen {
  height: calc(100% - 10px);
  margin-top: 5px !important;
  margin-bottom: 0px !important;
  overflow: hidden !important;
}

.homescreen .modal-content {
  height: 100%;
}
